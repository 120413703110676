.auth__page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dfdfdf;
  padding: 30px 35px;

  &_card {
    background-color: white;
    padding: 60px 30px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 31px;
    position: relative;
    min-width: 609px;
    max-width: 700px;

    &_inner {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;

      &.center {
        align-items: center;
      }
    }
  }

  h3 {
    font-family: 'Alice', serif;
    font-family: Alice;
    font-weight: 400;
    font-size: 36px;
    line-height: 41px;
    color: #160F47;
    margin: 0 0 30px;
  }
}

.form__item {
  width: 100%;
  margin-bottom: 20px;

  &.invite-email-item {
    max-width: 300px;
    margin: 0 auto 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  &_inner {
    display: flex;
    flex-direction: column;

    label {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #160F47;
      margin-bottom: 5px;
    }

    select, input {
      padding: 11px 0 11px 25px;
      border: 1px solid #E5E5E5;
      box-sizing: border-box;
      border-radius: 20px;
      outline: none;
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #160F47;
      width: 100%;

      &::placeholder {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #66637c;
      }
    }
  }
}

.forgot-password-link {
  margin-bottom: 20px;

  a {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #160F47;
    text-decoration: none;
  }
}

.bottom-link {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #160F47;
  text-align: center;
}

.primary-btn {
  background: var(--primary);
  border-radius: 90px;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
  border: none;
  outline: none;
  padding: 20px 30px;
  min-width: 206px;
  cursor: pointer;
  // width: 266px;
  margin: 0 auto;
  text-decoration: none;

  &:disabled {
    background: gray;
  }
}

.default-link {
  font-family: 'Roboto';
  font-weight: 600;
  line-height: 21px;
  color: var(--primary);
}


// Sign Up page
.guide-text {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #757575;
  margin: 0 0 10px;
}

.account__types {
  display: flex;
  justify-content: center;

  &_item {
    width: 240px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 2px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 20px;
    padding: 20px;
    cursor: pointer;

    .icon-part {
      width: 97px;
      height: 97px;
      border-radius: 50px;
      background: var(--primary);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      margin: 10px 0 0;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #160F47;
    }
  }
}

.back-link {
  position: absolute;
  top: 20px;
  display: flex;
  left: 20px;
  cursor: pointer;

  img {
    margin-right: 5px;
  }

  span {
    font-family: Roboto;    
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    color: var(--primary);
  }
}

.CustomCheckBox {
  display: block;
  margin-bottom: 2rem;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

    &:checked + label:after {
      content: '';
      display: block;
      position: absolute;
      top: 4.5px;
      left: 4px;
      width: 10px;
      height: 10px;
      border: solid var(--primary);
      background: var(--primary);
    }
  }

  label {
    position: relative;
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #160F47;

    &:before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid var(--primary);      
      border-radius: 3px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 10px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 25px;
    }
  }
}

.add-one {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
  margin: -16px 0 26px;
}

.error-msg {
  color: red;
  margin: 3px 0 5px;
  text-align: center;

  &.system-error {
    text-align: center;
    margin-bottom: 15px;
  }
}

.success-msg {
  color: green;
  text-align: center;
}

// Thanks page
.thanks-description {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #160F47;
  max-width: 491px;
  margin: 0;
}

.Termsconditions {
  position: relative;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #160F47;
}