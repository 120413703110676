.custom-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
  background: rgba(32, 49, 86, 0.6);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-card {
    max-width: 400px;
    background-color: white;
    border-radius: 7px;
    padding: 20px;
    position: relative;

    .close-icon {
      position: absolute;
      right: 15px;
      cursor: pointer;
    }

    input {
      padding: 11px 0 11px 25px;
      border: 1px solid #E5E5E5;
      box-sizing: border-box;
      border-radius: 20px;
      outline: none;
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #160F47;
    }
  }
}