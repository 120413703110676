h3 {
  font-family: 'Alice';
  font-weight: 400;
  font-size: 36px;
  line-height: 41px;
  color: #160F47;
  text-align: center;
}

.actions {
  text-align: center;
}

.paragraph {
  font-family: 'Alice';
  font-weight: 400;
  font-size: 20px;
  line-height: 41px;
  color: #160F47;
  text-align: center;
}

.loan-application-title {
  margin: 20px 0;
}

.color-pallete {
  max-width: 755px;
  margin: 0 auto;

  .color-picker-list {
    display: flex;
    margin-bottom: 40px;
  }
}

.upload-section-container {
  display: flex;
}

.upload-logo-section, .upload-video-section {
  max-width: 50%;
  margin: 20px;
  flex: 1;
}

.loan-application-section {
  margin: 20px;
}

.loan-application-section input {
  width: 50%;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}

.form__item_line {
  display: flex;
  width: 100%;

  input {
    flex: 1;
    margin-right: 10px;
    height: 24px;
  }

  .trash-icon {
    padding: 0;
    border: none;
    width: 20px;
    cursor: pointer;
  }
}

.add-another-btn {
  gap: .5rem;
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  font: italic normal .875rem/1.125rem Lora,serif;
  padding: 0;
  text-decoration: underline;
}