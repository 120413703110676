.prospective-clients {
  .content {
    padding: 34px 31px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #dfdfdf;
  }
 
  .section-header {
    border-bottom: 1px solid #C4C4C4;
  }

  .tabs {
    display: flex;    
    align-items: center;

    .tab-item {
      padding: 20px;        
      cursor: pointer;
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: #160F47;
      min-width: 150px;
      text-align: center;

      &.selected {
        border-bottom: 5px solid var(--primary);
        padding: 20px 20px 15px;
        font-family: 'Roboto';
        font-weight: bold;          
      }
    }
  }

  .user-list, .queue-listing {
    width: 100%;
  }

  table {
    width: 100%;

    thead {
      tr {
        th {
          width: 25%;
          background-color: var(--primary);
          font-family: 'Roboto';          
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          color: #FFFFFF;
          padding: 11px 0;
        }
      }
    }

    tbody {
      tr {
        td {
          font-family: 'Roboto';          
          font-weight: 400;
          font-size: 22px;
          line-height: 31px;          
          text-align: center;
          color: #0F1821;
          background-color: white;
          padding: 11px;

          .status {
            background: #FA9A64;
            border-radius: 10px;
            color: #0b0a0a;
            width: 200px;
            margin: 0 auto;
            font-size: 18px;

            &.approved {
              background: #00C91A;
            }

            &.rejected {
              background: #F97D7D;
            }
          }

          &.actions {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 26px;

            .button-group {
              display: flex;
              justify-content: space-around;
              width: 100%;
            }
            
            .approve {
              color: #00C91A;
              cursor: pointer;
            }

            .reject {
              cursor: pointer;
              img {
                margin-right: 10px;
              }

              span {
                color: #F97D7D;
              }
            }
          }
        }
      }
    }
  }

  .btn {
    border-radius: 90px;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    border: none;
    outline: none;
    padding: 20px 30px;
    cursor: pointer;
    text-decoration: none;
    min-width: 150px;

    &.approve-btn {
      background: #00C91A;
      margin-right: 10px;
    }

    &.reject-btn {
      background: #F97D7D;
    }

    &.default-btn {
      color: black;
      background: #bdbdbd;
      margin-left: 10px;
    }
  }
  
  &.user-detail {
    .avatar-section {
      display: flex;
      align-items: center;
  
      .main-image {
        margin-right: 20px;
        min-width: 100px;
        min-height: 100px;
        height: 100px;
        width: 100px;
  
        img {
          border-radius: 100%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .form__item {
      width: 50%;
      display: inline-block;
    }
  }
  
}