.default-layout {
  background: #dfdfdf;
  .navbar {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;

    .logo {
      
      img {
        height: 70px;
      }
    }

    .tabs {
      display: flex;    
      align-items: center;
  
      a {
        text-decoration: none;
      }
      
      .tab-item {
        padding: 20px;        
        cursor: pointer;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #160F47;
        min-width: 150px;
        text-align: center;
  
        &.selected {
          border-bottom: 5px solid var(--primary);
          padding: 20px 20px 15px;
          font-family: 'Roboto';
          font-weight: bold;          
        }
      }
    }

    
    .navs {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .nav-item {
        margin: 0 1.5rem;
        cursor: pointer;

        a {
          text-decoration: none;
        }

        p {
          font-family: 'Roboto';          
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          color: #160F47;
          text-decoration: none;
        }

      }

      .help {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          margin-right: 10px;
        }

        span {
          font-family: 'Roboto';          
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: var(--primary);
        }
      }
    }

    .avatar-section {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      .username {
        margin-right: 10px;
      }

      .avatar-image {
        width: 40px;
      }
    }

    .menu-actions {
      position: absolute;
      background: white;
      border-radius: 5px;
      box-shadow: 4px 4px 4px rgba(21, 84, 115, 0.05), 8px 8px 40px rgba(147, 169, 191, 0.28);
      top: 60px;
      right: 30px;
      padding: 4px 0;
      min-width: 160px;

      .action-item {
        padding: 12px 16px;
        background-color: white;  
        cursor: pointer;     

        a, p {
          font-family: Barlow;          
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          color: #6D7D97;
          margin: 0;
          text-decoration: none;
        }

        &:hover {
          background-color: #F5F6F8;
        }
      }
    }

    .logout {
      cursor: pointer;
    }
  }
}

.create-future-listing {
  background: #dfdfdf;
}

