.successful-unsubscription {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dfdfdf;
  height: 66vh;
  .message {
    font-family: 'Alice';
    font-weight: 400;
    line-height: 27px;
    color: #160F47;
  }
}
