.home-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px 15px;
  background: #dfdfdf;
  .title {
    font-family: 'Alice';
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    color: #160F47;
  }

  .video-section {
    width: 791px;
    margin: 0 auto;
    height: 437px;
    margin: 30px 0 100px;
    text-align: center;

    .embed-video {
      height: 100%;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.default-layout {
  background: #dfdfdf;
}