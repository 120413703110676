.buyer-future-listing {

  .section-header {
    padding: 28px 53px;
    background: white;
    display: flex;

    img {
      height: 70px;
    }

    .title {
      font-family: 'Roboto';
      font-size: 35px;
      line-height: 35px;
      color: #000000;
      text-align: left;
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 2%;
    }
    .subtitle {
      font-family: 'Roboto';
      font-size: 28px;
      line-height: 28px;
      color: #000000;
      text-align: left;
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 2%;
    }
  }

  .section-content {
    display: flex;
    width: 100%;
    margin-top: 23px;

    .left-section {
      width: 30%;
      padding: 30px;
      background: white;
    }

    .right-section {
      width: 68%;
      margin-left: 2%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .form__row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 50px;
    
    & > * {
      flex-basis: calc(50%);
    }
  }

  .action {
    text-align: center;
  }

  .send-loan {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-decoration: underline;
    color: var(--primary);
    margin-top: 30px;
    padding: 8px 26px;
    border-radius: 20px;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .no-result {
    font-size: 30px;
    background: white;
    padding: 20px;
    text-align: center;
    width: calc(100% - 50px);
  }

  .group-item {
    margin-top: 10px;

    .group-title {
      font-family:'Roboto';
      font-weight: bold;
      font-size: 24px;
      line-height: 26px;
      color: var(--primary);
      padding: 10px;
    }
  }

  .table-item {
    margin-bottom: 20px;

    &-header {
      // display: flex;
      // grid-template-columns: repeat(4, 1fr);
      font-family:'Roboto';
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: var(--primary);
      background: #dfdfdf;
      text-align: center;

      &-cell {
        padding: 15px 0px;
      }
    }

    &-content {
      // display: flex;
      // grid-template-columns: repeat(4, 1fr);
      font-family: 'Alice';
      font-weight: 400;
      font-size: 20px;
      line-height: 55px;
      color: #160F47;
      background: #FFFFFF;
      text-align: center;
      
      &-cell {
        padding: 30px 10px;

        .primary-btn {
          font-size: 18px;
          line-height: 21px;
          padding: 14px;
        }
      }
    }
  }
}

.navbar+.content > .buyer-future-listing {
  padding: 14px 42px;
}
