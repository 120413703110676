.profile-settings {
  h3 {
    font-family: 'Alice';
    font-weight: 400;
    font-size: 36px;
    line-height: 41px;
    color: #160F47;
    text-align: center;
  }

  .white-card {
    h5 {
      font-family: 'Roboto';
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      color: #160F47;
    }
  }

  .avatar-section {
    display: flex;
    align-items: center;

    .main-image {
      margin-right: 20px;
      min-width: 100px;
      min-height: 100px;
      height: 100px;
      width: 100px;

      img {
        border-radius: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    button {
      border: 1px solid #D1D5DB;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #160F47;
      padding: 9px 13px;
      cursor: pointer;
      background-color: white;
    }

    #upload-company-logo {
      display: none;
    }
  }

}