.image-uploader-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  
  .display-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    width: 100%;     

    
    .icon-text-box {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 2px dashed #D1D5DB;
      box-sizing: border-box;
      border-radius: 6px;
      width: 100%;
      height: 170px;     

        .main-text {
          text-align: center;

          .drop {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: var(--primary);

            span {
              font-family: 'Roboto';
              color: #160F47;
            }
          }

          .allowed-type {
            font-family: 'Roboto';            
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #9CA3AF;
          }
        }
      
      .error-notification {           
          position: absolute;
          top: 20px;
          background-color: #dfb852;
          border-radius: 5px;
          color: #444;
          padding: 5px 10px;
          p {
            margin: 0;
            text-shadow: none;
          }
      }
    }

    .upload-image-input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }  
}