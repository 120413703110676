body {
  background: #dfdfdf;
  .container {
    max-width: 1236px;
    margin: 0 auto;
    padding: 20px;
  }

  .white-card {
    padding: 40px;
    background: #FFFFFF;
    margin-bottom: 10px;

    &.padding-sm {
      padding: 15px 40px;
    }

    .section-title {
      font-family: 'Roboto';
      font-weight: bold;
      font-size: 24px;
      line-height: 20px;
      text-align: center;
      color: #160F47;
    }
  }

  .custom-select {

    .custom-select__placeholder, .custom-select__single-value {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;      
      color: #000000;
    }
  }
  
  .custom-select__indicator-separator {
    display: none;
  }
  
  .custom-select__menu {
    margin-top: 2px !important;
    border-radius: 5px !important;
    padding: 0 !important;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.005);
    border: 0.5px solid #F2F2F2;
    background: #FEFEFF;
    
    .custom-select__option {
        cursor: pointer;
    }
  }
  
  .custom-select__control {
    border: 1px solid #E5E5E5 !important;
    padding-left: 14px;
    border-radius: 20px;
    padding: 3px 0 3px 14px;
  }
  
  .custom-select__control--is-focused {
    box-shadow: none !important;  
    background: #FEFEFF;
    border: 1px solid #F2F2F2 !important;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.005);
  }
}
